import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { Observable } from 'rxjs';
import { Lead } from '@models/lead.interface';
import {Timeline} from "@models/timeline.interface";

@Injectable({ providedIn: 'root' })
export class LeadService {
  constructor(
    private http: HttpClient,
    public utilityService: UtilityService
  ) {}

  getProjectSales(): Observable<Array<Lead>> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/projects-sale');
  }

  getProjectPurchases(): Observable<Array<Lead>> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/projects');
  }

  getProjectSaleById(id: string): Observable<Lead> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/projects-sale/' + id);
  }

  getLeadTimeline(id: string): Observable<Timeline> {
    return this.http.get<any>(this.utilityService.getApiEndpoint() + '/timeline/' + id);
  }
}
