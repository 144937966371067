import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Lead } from '@models/lead.interface';
import { Observable } from 'rxjs';
import { getSelectedLeadTimeline } from '../store/leads.selectors';
import { Store } from '@ngrx/store';
import { LeadsState } from '../store/leads.state';
import { Timeline } from '@models/timeline.interface';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-timeline',
  template: `
    <div *ngIf="timeline$ | async as timeline" class="max-h-[65vh] overflow-y-auto">
      <div *ngFor="let data of timeline.data" class="border rounded border-gray p-4 mb-4">
        <p class="text-sm text-neutral-400">
          {{ (data.createdAt | date : 'dd/MM/yyyy') + ' ' + (data.createdAt | date : 'HH:mm') }}
        </p>
        <div class="flex my-2">
          <fa-icon [icon]="faCircle" class="text-primary mr-2"></fa-icon>
          <p>{{ data.title }}</p>
        </div>

        <p *ngIf="data.description" class="font-bold">{{ labels[data.description] }}</p>
        <p class="mt-2 text-sm">Operatore:</p>
        <p>{{ data.operator }}</p>
      </div>
    </div>
  `,
})
export class TimelineComponent {
  @Input() lead: Lead;
  @Output() leadUpdated: EventEmitter<Lead> = new EventEmitter<Lead>();
  timeline$: Observable<Timeline> = this.store.select(getSelectedLeadTimeline);
  faCircle = faCircle;
  labels: Array<string> = [
    'Primo appuntamento',
    'Da valutare',
    'Valutazione consegnata',
    'Standby',
    'Incarico',
    'Proposta trattativa',
    'Venduto',
    'Appuntamento da fissare',
    'KO',
    'KO Sopralluogo',
    'Richiesta contestazione',
    'KO Gestione',
    'KO Recesso',
    'KO Incarico Scaduto',
  ];
  constructor(private store: Store<LeadsState>) {}
}
