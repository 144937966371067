import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  public getApiEndpoint() {
    return environment.apiGatewayURL;
  }

}
