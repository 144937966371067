import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeadsState } from './leads.state';

const getFeatureState = createFeatureSelector<LeadsState>('leads');

export const getSelectedLead = createSelector(getFeatureState, ({ selectedLead }) => selectedLead);

export const getLeads = createSelector(getFeatureState, ({ leads }) => leads);

export const getLeadOwner = createSelector(getFeatureState, ({ selectedLead }) => selectedLead);

export const getSelectedLeadTimeline = createSelector(
  getFeatureState,
  ({ selectedLeadTimeline }) => selectedLeadTimeline
);
